import React, { useEffect } from 'react';
import "../../../styles/Dashboard/Dashboard.scss"
const Security = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, []);

    let items = [
        {
            header: "Security & Privacy Policy",
            body: "We value your privacy and ensure a secure purchase. Robot Canada Inc at FreeMotionShop.com is committed to respecting and protecting your privacy and confidential information. Once we have received your data, we will apply strict procedures to prevent unauthorized access; however, we cannot guarantee the security of the information. We will never share your information unless we are legally obliged to do so. Uses of Information",
            item: ["We don’t sell or rent your personal information to any third party",
                "We utilize your information to fulfill your requests, meet your needs, administer various programs, deliver product and services, and attain other formal purposes",
                ".Your personal information may be used to provide you latest marketing communications",
                ".In some circumstances, FreeMotion may share your details with certain third parties to execute services on our behalf."
            ]
        },
        {
            header: "Updates ",
            body: "Should we amend, update or make any variations to our security and privacy policy, we will post the changes here.",
            item: [""]
        },
        {
            header: "Analytics",
            body: "We may collect information regarding your computer, including your browser type, operation system, and IP address, to create reports, maintain a safe environment for our users, and meet system administration requirements. We may analyze details of your visits to our website, or usage of our mobile app, including, but not limited to, location, traffic, and other communication data and resources that you access.",
            item: [""]
        },
        {
            header: "Cookies",
            body: "Our cookies help you to personalize your experience with our website, and ensure your sign up process is as quick and safe as possible. These do not share any of your personal information with us; moreover, we would never utilize any details delivered through cookies to try to identify anyone.",
            item: [""]
        },
        {
            header: "Contact",
            body: "We welcome your queries, comments, and requests regarding our security and privacy policy, and encourage you to address them via the contact form.",
            item: [""]
        },
        {
            header: "Consent",
            body: "By accessing our website FreeMotionShop.com, you as a result of this consent to our security and privacy policy and accept its terms.",
            item: [""]
        },
    ]

    return (<div className='security_wrapper'>
        {items.map(i => (
            <div className='security_item'>
                <h1>{i.header}</h1>
                <h2>{i.body}</h2>
                {i.item.map(k => <h2>{k}</h2>)}
            </div>
        ))}
    </div>);
}

export default Security;