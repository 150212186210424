import React, { useEffect, useState } from "react";
import Modal from "../../components/HelpersComponents/Modal.Component";
import { Spin, Form, Input, Button, Row, Col, Select } from "antd";
import { addressFormatter, onlyNumeric } from "../../services/Utilities";
import { getAddressFromLatLng } from "../../components/HelpersComponents/MapComponents/Map.Component";
import Address from "../../components/HelpersComponents/Address.Component";
import s from './style.module.scss'
import { Get, Post } from "../../api/Http.Client";

export const RegistrationModal = (props) => {
	const { showModal, setShowModal, loading = false, updateLocation } = props;
	const [location, setLocation] = useState('')
	const [RTS, setRTS] = useState([])

	const newPartnerFetch = (body) => {
		Post('api/v1/restaurant/registration', {}, body, false)
			.then(({
				success,
				message
			}) => {
				if (success) {
					setMessage('Success!')
					setTimeout(() => setShowModal(false), 500)
				} else {
					setMessage(message)
				}

			}).catch(() => setMessage('Error...'))
	}

	const [message, setMessage] = useState('')

	useEffect(() => {
		Get('api/v1/restaurant-types', {}, false, true)
			.then(({ success, data }) => {
				if (success)
					setRTS(data?.restaurantTypes.map(item => (
						{ value: item.slug, label: item.name }
					)))
			}
			)
	}, [])

	const onPlaceSelected = async (place) => {
		const address = addressFormatter(place);
		let selectedLocation = {
			address: address.address,
			city: address.city,
			state: address.state,
			lat: address.lat,
			log: address.log,
			zipCode: address.zip,
			flat: '',
			buildingName: ''
		};
		if (
			!selectedLocation.zipCode ||
			!selectedLocation.city ||
			!selectedLocation.state
		) {
			await getAddressFromLatLng(
				selectedLocation.lat,
				selectedLocation.log
			).then((res) => {
				const newLocation = addressFormatter(res);

				if (Object.keys(newLocation).length) {
					delete newLocation.address;
					delete newLocation.lat;
					delete newLocation.log;
					selectedLocation = { ...selectedLocation, ...newLocation };
				}
			});
		}
		setLocation(selectedLocation)
	};
	
	const getInputState = (e) => {
		if (!e.length) {
			updateLocation({});
		}
	};

	const handleSubmit = values => {
		newPartnerFetch({
			name: values.name,
			description: values.description,
			email: values.email,
			phone: values.phone,
			fein: values.fein,
			restaurantTypeSlug: values.restaurantTypeSlug,
			location
		})
	};

	return (
		<Modal
			style={{ width: 1000 }}
			className={"SignIn Register"}
			visible={showModal}
			onCancel={() => setShowModal(false)}
		>
			<Spin spinning={loading}>
				<h2 className={"SignInTitle text-center"}>Register Partner</h2>
				<p style={{
					fontSize: '30px',
					textAlign: 'center',
					marginBottom: 0,
					color: message === 'Success!' ? 'green' : 'red'
				}}>{message}</p>
				<Form
					onFinish={handleSubmit}
					className="login-form"
					name={"registration_form"}
					layout={'vertical'}
				>
					<Row type="flex"
					>
						<Col type="flex"
							align="middle">
							<Form.Item
								label={"Name"}
								name={"name"}
								rules={[
									{
										required: true,
										message: "Please input name!"
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label={"Description"}
								name={"description"}
								rules={[
									{
										required: true,
										message: "Please input description!"
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								label={"Email"}
								name={"email"}
								rules={[
									{
										required: true,
										message: "Please input your Email address!",
										type: "email"
									}
								]}
							>
								<Input autoComplete={"username email"} />
							</Form.Item>
							<div className={s.address}>
								<Address
									isDelivery={true}
									getInputState={getInputState}
									value={location?.address}
									type="search"
									placeholder="Address"
									title="Address"
									onPlaceSelected={onPlaceSelected}
									types={["address"]}
									componentRestrictions={{ country: "us" }}
									required={true}
								/>
							</div>
						</Col>

						<Col type="flex"
						>
							<Form.Item
								label={"Phone number"}
								name={"phone"}
								rules={[
									{ required: true, message: "Please input your Phone number!" },
									{ min: 9, message: "Value must be greater than or equal to 9 !" },
									{ max: 13, message: "Value must be less than or equal to 13 !" }
								]}
							>
								<Input
									onKeyPress={onlyNumeric}
								/>
							</Form.Item>
							<Form.Item
								label={"Fein"}
								name={"fein"}
								rules={[
									{
										required: true,
										message: "Please input fein!",
									}
								]}
							>
								<Input />
							</Form.Item>

							<Form.Item
								label={"Restaurant Type"}
								name={"restaurantTypeSlug"}
								rules={[
									{
										required: true,
										message: "Please input fein!",
									}
								]}
							>
								<Select options={RTS} />
							</Form.Item>
						</Col>

					</Row>

					<Button type="primary" htmlType="submit" className="login-form-button">
						Send
					</Button>
				</Form>
			</Spin>
		</Modal>
	);
};

