import React, { useEffect, useState } from 'react';
import { connect } from "react-redux"
import { changeArrivalTime } from "./../../actions/checkoutAction";
import SheduleModal from '../../container/SheduleModal/SheduleModal';

const ArrivalTime = ({ changeArrivalTime, isDelivery, scheduleDate }) => {
    useEffect(() => {
        if (scheduleDate.date && scheduleDate.time) {
            setIsSchedule(true)
        }
    }, [scheduleDate.date, scheduleDate.time]);
    const [isSchedule, setIsSchedule] = useState(false);

    if (!isDelivery) return false
    return <>
        <div className='sp_option'>
            <p className='sp_option_header'>Delivery time</p>
            <div>
                <button className={!isSchedule ? 'active' : ''} onClick={() => {
                    setIsSchedule(false)
                    changeArrivalTime({
                        bool: false,
                        value: {}
                    })
                }}>Now
                </button>
                <button className={isSchedule ? 'active' : ''} onClick={() => {
                    setIsSchedule(true)
                    changeArrivalTime({
                        bool: true,
                        value: {}
                    })
                }}>Schedule
                </button>
            </div>
            {isSchedule && <SheduleModal
                getValues={(date, type) => {
                    changeArrivalTime({
                        bool: true,
                        value: {
                            ...scheduleDate,
                            [type]: date
                        }
                    })
                }}
            />
            }
        </div>
    </>
}
const mapStateToProps = (state) => ({
    schedule: state.checkout.schedule.schedule,
    scheduleDate: state.checkout.schedule.scheduleDate,
    isDelivery: state.isDelivery
});

const mapDispatchToProps = {
    changeArrivalTime
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ArrivalTime);
