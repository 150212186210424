import React from 'react';
import "../../styles/CateringBussiness/CateringBussiness.scss"
import CateringBussiness from "../../img/CateringBussiness.png"
const CateringBusiness = () => {
    return (<div className="catering_bussiness_wrapper">
        <img src={CateringBussiness} alt="CateringBussiness" />
        <h1>We can help you grow your catering business</h1>
        <h2>Through our platform you will get more catering orders. Just prepare and hand us the food and we’ll
            take care of the rest. Our dedicated drivers will make sure to present the order just like it’s meant to
            be. With insulated bags and professional manner your order will be served on time, every time!
            <a href="/">View More</a>
        </h2>
    </div>)
}
export default CateringBusiness