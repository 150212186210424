import * as OrderAPI from '../api/Order.API';
import * as BasketApi from '../api/Basket.API';
import { handleResponse } from "./Utilities";

export const calculateBasket = basket => {
	const
		restaurant = basket.restaurant,
		products = basket.products;

	let subtotal = 0,
		deliveryPrice = restaurant ? (restaurant.isFreeDelivery ? 0 : restaurant.delivery) : 0,
		count = 0,
		deliveryMinimum = restaurant ? restaurant.deliveryMinimum : 0,
		minDeliveryTime = restaurant ? restaurant.deliveryTime : 0,
		maxDeliveryTime = restaurant ? restaurant.deliveryTime : 0;

	if (restaurant) {
		for (let productKey in products) {
			const product = products[productKey];

			subtotal += product.singlePrice * product.counts;

			products[productKey].price = product.singlePrice * product.counts;

			minDeliveryTime = product.deliveryTime < minDeliveryTime ? product.deliveryTime : minDeliveryTime;
			maxDeliveryTime = product.deliveryTime > maxDeliveryTime ? product.deliveryTime : maxDeliveryTime;

			count++;
		}
	}

	const storageBasketObj = Object.assign({}, basket, {
		subtotal: subtotal,
		delivery: deliveryPrice,
		deliveryMinimum: deliveryMinimum,
		productCount: count,
		deliveryTime: {
			min: minDeliveryTime,
			max: maxDeliveryTime
		}
	});

	updateBasket({ restaurant: storageBasketObj.restaurant, isCatering: basket.isCatering, products: storageBasketObj.products });
	return storageBasketObj;
};

export const orderSubmit = model => {
	return OrderAPI.submitOrder(model)
		.then(result => result)
};

export const orderCateringSubmit = model => {
	return OrderAPI.submitCateringOrder(model)
		.then(result => handleResponse(
			result,
			undefined,
			() => result,
			undefined,
			'Your order has been successfully submitted.'
		))
}

export const clearBasket = () => {
	localStorage.setItem("Basket", JSON.stringify({}));
};

export const getBasket = () => {
	return BasketApi.getBasket().then(({ data }) => data)
};

export const updateBasket = basket => {
	localStorage.setItem("Basket", JSON.stringify(basket));
};
