import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import img from "../../img/thankYou.png";
import { changeStep } from '../../actions/checkoutAction';
import Config from "Config";

import "../../styles/ShippingPayment/ShippingPayment.scss";

const ws = new WebSocket(Config.WssUrl)

const OrderedPage = ({ user, uuidCode,changeStep }) => {
    const history = useHistory();
    useEffect(() => {
        ws.onopen = () => {
            ws.send(JSON.stringify({
                action: "new",
                slug: uuidCode
            }))
        }
        return (() => {
            ws.close();
            changeStep(1)
        })
    }, []);

    return <>
        <div className='ordered_page_wrapper' >
            <div>
                <p>Thank you</p>
                <span>
                    Order Number:<span>{uuidCode}</span>
                </span>
                <span>We have received your order</span>
                <span>Please check your email. We have sent a confirmation to your address<br />{user.email}</span>
                <img src={img} alt="Thank You" />
                <button onClick={() => {
                     history.push("/")
                    // socket.emit("new", "msg")
                }}>Go To Menu</button>
            </div>
        </div>
    </>
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    uuidCode: state.checkout.uuidCode,
});

const mapDispatchToProps = {
    changeStep
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderedPage);
