import React, { useEffect, useState } from 'react';
import { connect } from "react-redux"
import { OrderDeliveryType } from '../../enums/Order.Status.Enum';
import { updateOrderDeliveryType } from "../../actions/orderActions";
import Config from "Config";
import { AddressChangeModal } from "../AddressChangeModal/AddressChangeModal";
import { useLocation } from "react-router";

const DeliveryOption = ({ updateOrderDeliveryType, isDeliveryStatus, setIsDeliveryStatus, isOpen, setIsopen }) => {
    const location = useLocation()
    const BaseUrl = Config.ServerUrl;
    const [changeModal, setChangeModal] = useState(false)
    const locationDateStorage = JSON.parse(localStorage.getItem('LocationData') || '{}')

    const changeToDelivery = () => {
        setIsDeliveryStatus(true)
        updateOrderDeliveryType(OrderDeliveryType.IS_DELIVERY)
        sessionStorage.setItem('isDelivery', 'true')
        fetch(BaseUrl + `/api/v1/restaurant/details/${localStorage.getItem('restaurantSlug')}`, {
            headers: {
                UserLocation: locationDateStorage?.lat + '|' + locationDateStorage?.log
            }
        })
            .then(data => data.json())
            .then(data => !data.success && setChangeModal(true))
            .catch(_ => setChangeModal(true))
    }

    const changeToPickUp = () => {
        setIsDeliveryStatus(false)
        updateOrderDeliveryType(OrderDeliveryType.IS_PICK_UP)
        sessionStorage.setItem('isDelivery', 'false')
    }

    useEffect(() => {
        fetch(BaseUrl + `/api/v1/restaurant/details/${localStorage.getItem('restaurantSlug')}`, {
            headers: {
                UserLocation: locationDateStorage?.lat + '|' + locationDateStorage?.log
            }
        })
            .then(data => data.json())
            .then(data => setChangeModal(!data.success))
            .catch(err => console.error(err))
    }, [location.pathname])

    useEffect(() => {
        window.scrollTo(0, 0)
        document.querySelector('body').style = `height: ${changeModal ? '100vh' : 'auto'}; overflow: ${changeModal ? 'hidden' : 'auto'}`
    }, [changeModal])

    return (
        <>
            {(changeModal || isOpen) && <AddressChangeModal setIsDeliveryStatus={setIsDeliveryStatus} setIsopen={setIsopen} setState={setChangeModal} />}
            <div className='sp_option'>
                <p className='sp_option_header'>Delivery option</p>
                <div>
                    <button
                        className={isDeliveryStatus ? 'active' : ''}
                        onClick={changeToDelivery}>
                        Delivery
                    </button>
                    <button
                        className={!isDeliveryStatus ? 'active' : ''}
                        onClick={changeToPickUp}>
                        Pick Up
                    </button>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    isDelivery: state.isDelivery,
});

const mapDispatchToProps = {
    updateOrderDeliveryType
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DeliveryOption);
