import React, { useEffect } from 'react';
import "../../../styles/Dashboard/Dashboard.scss"
const Terms = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, []);

    let items = [
        {
            header: "TERMS",
            body: [`Canada & USA Customers:`,
                `FreeMotion is happy to accept as payment all major credit cards, PayPal, and Klarna. We have financing options available – Klarna for US customers and Paybright for Canadian customers.
            We offer USD prices for United States customers and CAD prices for Canadian customers.
            If your item is in stock it will ship within 1-3 business days of approved and cleared payment.
            Orders are processed in order of receipt and approved payment. If your item is not in stock or stock is depleted it will be back-ordered. Back ordering generally adds an additional 2-10 business days. If the item will not be in stock within the backorder time period, you will have the option to wait for a backorder, modify the order, or cancel the order with a full refund.
            FreeMotion offers free shipping within Canada and the contiguous 48 United States.
            `,
                `International Customers:`,
                ` Freemotion does not currently ship internationally.`
            ],
        },
        {
            header: "DELIVERY INFORMATION ",
            body: [`All Domestic orders include Free Standard Shipping, excluding Alaska, Hawaii, and outlying territories.
            Under normal circumstances, your item(s) will ship from our U.S. Warehouse within 24-48 hours of your payment clearing. We only ship Monday through Friday. Any in-stock orders placed Friday through Sunday will ship the following week.
            We only ship to verified addresses which match the credit card used. We will not change the delivery address once payment has been submitted.
            The estimated delivery time is 4 to 5 days, except during holidays, when delivery may take longer.
            Your order will be shipped by the carrier that can best meet the delivery timeframe; we will use either FedEx or UPS if shipped within the continental 48 states and Canada.`]

        },
        {
            header: "DELIVERY INFORMATION",
            body: [`All Domestic orders include Free Standard Shipping, excluding Alaska, Hawaii, and outlying territories.
            Under normal circumstances, your item(s) will ship from our U.S. Warehouse within 24-48 hours of your payment clearing. We only ship Monday through Friday. Any in-stock orders placed Friday through Sunday will ship the following week.
            We only ship to verified addresses which match the credit card used. We will not change the delivery address once payment has been submitted.
            The estimated delivery time is 4 to 5 days, except during holidays, when delivery may take longer.
            Your order will be shipped by the carrier that can best meet the delivery timeframe; we will use either FedEx or UPS if shipped within the continental 48 states and Canada.`,
                `ORDER CANCELLATION:`,
                `You can cancel your order if your item has not been shipped yet.
        If you refuse the package upon delivery, you will, unfortunately, be charged for the RETURN shipping.`,
                `TRACKING PACKAGES:`,
                `A tracking number will be provided once your item is shipped. A shipment notification, including the tracking number, will be sent to the email you provide us with. `,
                `Items Damaged During Transit:`,
                `If you receive a damaged item, you must contact us within 7 days by calling +1 (626) 295-6599 / 
       +1 (514) 922-7332, or by email at info@freemotionshop.com. If you do not contact us within this time period, your damage claim may be denied by the carrier. You assume all responsibility for the damaged item before it is picked up by the corresponding carrier agents.`
            ]

        },
        {
            header: "RETURN POLICY",
            body: [`FreeMotion is happy to offer 14 days free returns. A return request must meet the following criteria:`,
                `Receiving defects or the wrong item`,
                `Delayed shipment`, `We will apply restocking fees for opened, tested, or used items depending on the condition of the item.
                We, unfortunately, must require a 10% restocking fee for customer changing their minds or refusing a shipment.
                All customers must provide a Return Merchandise Authorization (RMA) code and a reason for the return.
                We will gladly provide your refund 3 days after we receive the item and inspect it.
                Any shipping charges incurred when returning a product to FreeMotion are the responsibility of the customer. Returned products must be undamaged, clean, and in otherwise new condition with all original materials i.e. original packaging, manuals, and accessories, and must be accompanied by the original invoice.`
            ]

        },
        {
            header: "WARRANTY INFORMATION",
            body: [`We offer a generous 12-month Limited Warranty that covers the original purchaser from any defects under normal use from the date of purchase. We also offer free technical support for 12 months, including the help of getting repair service and spare parts.
            We offer a 6 months warranty for our used certified items.
            Unfortunately, our warranty does not cover water damage or any kind of physical damage including road accidents.
            Items modified by the customer are not covered.`,
                `Warranty Limitations:`,
                `This Limited Warranty covers the original purchaser from any defects in material or workmanship under normal use for one year from the date of the invoice. This warranty is only offered to the original purchaser of the product and is not transferable to a subsequent purchaser.
                 Freemotion will either repair or replace the product at no charge, using new or refurbished replacement parts at our discretion. Replacements may be different but functionally equivalent models. This Limited Warranty does not cover any problem that is caused by conditions, malfunctions, or damage not resulting from defects in material or workmanship. These conditions may include but are not limited to, road hazards, accidents, and improper operation or maintenance. Items returned for refund must be returned within 30 days from delivery. Under no circumstances will we issue refunds after 30 days of delivery.`,
                `Replacement Extended Warranty:`, `All replacement products are covered by the standard warranty starting at the date of the original delivery. If for various reasons the replacement procedure extends beyond the warranty period, we at our discretion will extend the warranty coverage on such replacement up to an additional 30 days. Please be advised that this extended warranty is final and any additional extensions are non-negotiable.`, `Limits and Exclusions:`,
                `There are no express warranties except as listed above. FreeMotion shall not be liable for special, incidental, consequential or punitive damages, including, without limitation, direct or indirect damages for personal injury, loss of goodwill, profits or revenue, loss of use this product or any associated equipment, cost of substitute equipment, downtime cost, or any other losses, or claims of any party dealing with buyers from such damages, resulting from the use of or inability to use this product or arising from breach of warranty or contract, negligence, or any other legal theory.
            In no event shall FreeMotion be liable for any incidental, indirect, special or consequential damages or liabilities (including but not limited to incidental or consequential damages for loss of time, inconvenience, loss of use of product, or any other consequential or incidental loss) in connection with the purchase, use, or operation of the product. FreeMotion is not liable for property damage, personal injury, or death. All express and implied warranties, including the warranties of merchantability and fitness for a particular purpose, are limited to the applicable warranty period set forth above.`

            ]

        },
    ]
    
    return <div className='terms_wrapper'>
        {items.map(i => (
            <div className='terms_item'>
                <h1>{i.header}</h1>
                {i.body.map(k => <div>{k}</div>)}

            </div>
        ))}
    </div>
}
export default Terms