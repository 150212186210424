import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { connect, useSelector } from "react-redux";
import Img from "./Img.Component";
import LocationIcon from "../../img/ic_location.svg";
import search from "../../img/search.svg";

import Address from "./Address.Component";
import { getUserLocation } from "../../services/User.Service";
import {
    addressFormatter,
    messageType,
    showMessage,
} from "../../services/Utilities";
import { updateLocation } from "../../actions/locationActions";
import { getAddressFromLatLng } from "./MapComponents/Map.Component";
import { BasketEmptyInfoModal } from "../LayoutComponetns/PartialsComponents/Basket.Empty.Info.Modal";
import { clearBasketAction } from "../../actions/basketActions";
import { updateClientLocation } from "../../services/Base.Service";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { showModal } from "../../actions/baseActions";
import { ModalTypes } from "../../enums/Modal.Types.Enum";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const AddAddress = ({
    inputPlaceholder = "Enter Your Address",
    showButton = true,
    defaultValue,
    onAddressConfirmed = (f) => f,
    onAddressSelected = (f) => f,
    updateLocation,
    clearBasketAction,
    getLocation = (f) => f,
    isDelivery,
    showModal,
}) => {
    const locationPath = useLocation().pathname;
    const history = useHistory();
    const currentLocation = useSelector((state) => state.currentLocation.address);
    const basketProductCount = useSelector((state) => state.basket.productCount);
    const errInputBoxMsg = useRef(null);
    const [location, setLocation] = useState(currentLocation);
    const prevLocation = usePrevious(currentLocation);

    useEffect(() => {
        if (
            currentLocation &&
            prevLocation &&
            currentLocation.address !== prevLocation.address
        ) {
            setLocation(currentLocation);
        }
    }, [currentLocation, prevLocation]);

    // select address from list
    const onPlaceSelected = async (place) => {
        const address = addressFormatter(place);

        let selectedLocation = {
            address: address.address,
            city: address.city,
            state: address.state,
            lat: address.lat,
            log: address.log,
            zipCode: address.zip,
        };

        //So we are checking address second time with log lat and merge objects.
        if (
            !selectedLocation.zipCode ||
            !selectedLocation.city ||
            !selectedLocation.state
        ) {
            await getAddressFromLatLng(
                selectedLocation.lat,
                selectedLocation.log
            ).then((res) => {
                const newLocation = addressFormatter(res);

                if (Object.keys(newLocation).length) {
                    delete newLocation.address;
                    delete newLocation.lat;
                    delete newLocation.log;
                    selectedLocation = { ...selectedLocation, ...newLocation };
                }
            });
        }

        setLocation(selectedLocation);
        onAddressSelected && onAddressSelected(selectedLocation);
        getLocation(selectedLocation);
    };

    // confirm selected address click confirmation button
    const onPlaceConfirmed = () => {
        if (location.address !== undefined) {
            // clear error message box
            const msgBox = errInputBoxMsg.current;
            msgBox.innerHTML = "";
            msgBox.classList.remove("errInputBoxMsg");
            document
                .getElementsByClassName("vehicleInformation")[0]
                .classList.remove("errInputBox");

            // case 1 when address is not changed
            if (
                currentLocation &&
                currentLocation.address &&
                currentLocation.address === location.address
            ) {
                setConfirmedAddress(location);
                return;
            }

            // if basket isn't empty show information popup
            if (!!basketProductCount) {
                BasketEmptyInfoModal(clearBasketAction, () => {
                    setConfirmedAddress(location);
                });
            } else {
                setConfirmedAddress(location);
            }
        } else {
            let msgBox = errInputBoxMsg.current;
            msgBox.classList.add("errInputBoxMsg");
            document
                .getElementsByClassName("vehicleInformation")[0]
                .classList.add("errInputBox");
        }
    };

    const setConfirmedAddress = (confirmedLocation) => {
        updateClientLocation(confirmedLocation).then((result) => {
            if (result) {
                updateLocation(confirmedLocation);
                onAddressConfirmed && onAddressConfirmed(confirmedLocation);
            } else updateLocation({});
        });
    };

    // get client current lat lng using browser API
    const getClientCurrentLocation = () => {
        getUserLocation().then((location) => {
            if (location) {
                setLocation(location);
                onAddressSelected && onAddressSelected(location);
            }
            return location;
        });
    };
    const deleteLocation = () => {
        updateLocation({});
        setLocation({});
    };

    return (
        <>
            <div className={"getFoodButton"}>
                <span>
                    <Img
                        src={LocationIcon}
                        alt="Location Icon"
                        isLocale={true}
                        onClick={getClientCurrentLocation}
                    />
                </span>
                <Address
                    deleteLocation={deleteLocation}
                    getLocation={getLocation}
                    isDelivery={isDelivery}
                    type="search"
                    onClick={deleteLocation}
                    placeholder={isDelivery ? inputPlaceholder : ""}
                    defaultValue={defaultValue || (location.address && isDelivery ? location.address : "")}
                    title={location.address && isDelivery ? location.address : ""}
                    onPlaceSelected={onPlaceSelected}
                    types={["address"]}
                    componentRestrictions={{ country: "us" }}
                    required={isDelivery}
                />
                {showButton && (
                    <button
                        onClick={() => {
                            onPlaceConfirmed();
                            if (locationPath === "/shopping-cart") {
                                showModal(ModalTypes.LOCATION_MODAL, false);
                            } else {
                                if (!isDelivery) {
                                    history.push("/restaurant-menu");
                                } else {
                                    if (!location?.address) {
                                        showMessage(messageType.info, "Please enter your address");
                                        return false;
                                    } else {
                                        history.push("/restaurant-menu");
                                    }
                                }
                            }
                        }}
                    >
                        <Img
                            src={search}
                            alt={"Get Food"}
                            isLocale={true}
                            className={"getFoodIcon"}
                        />
                        Find Food
                    </button>
                )}
            </div>
            <div ref={errInputBoxMsg} />
        </>
    );
};

AddAddress.propTypes = {
    updateLocation: PropTypes.func.isRequired,
    clearBasketAction: PropTypes.func.isRequired,
    inputPlaceholder: PropTypes.string,
};

const mapStateToProps = (state) => ({
    isCatering: state.isCatering,
    isDelivery: state.isDelivery,
});

const mapDispatchToProps = {
    updateLocation,
    clearBasketAction,
    showModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
