import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import filterPng from "../../../img/filter.png";

import { selectedRestaurantProduct, showModalRegularMenu, updateSelectedRestaurant, } from "../../../actions/baseActions";

import "./../../../styles/SelectedRestaurants/SelectedRestaurant.scss";

import RestaurantsProducts from "./RestaurantProduct";
import { Spin } from "antd";
import { RestaurantTitle } from "./RestaurantTitle";
import RestaurantInfo from "./RestaurantInfo";
import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import PopUP from "../PopUP";
// import SelectedType from "../SelectedType";
import LeftMenuComponent from "../../HomeComponents/LeftMenu.Component";
import { showFilterModal } from "../../../actions/filterActions";

const SelectedRestaurant = ({
    selectedRestaurant,
    selectedRestaurantProduct,
    updateSelectedRestaurant,
    selectedRestaurantProductArr,
    showModalRegularMenu,
    showModal,
    filter,
    filterModal,
    width,
    showFilterModal,
    localLoading,
    isAuth,
}) => {
    const { restaurantSlug } = useParams();

    useEffect(() => {
        updateSelectedRestaurant(restaurantSlug, isAuth);
    }, [restaurantSlug]);

    useEffect(() => {
        let newFilter = {
            searchText: filter?.searchText,
            priceMin: filter?.priceMin,
            priceMax: filter?.priceMax,
        };
        if (filter.priceMin !== null && filter.priceMax !== null) {
            selectedRestaurantProduct(restaurantSlug, newFilter);
        }
    }, [restaurantSlug, filter.searchText, filter.priceMin, filter.priceMax]);

    useEffect(() => {
        return () => {
            updateSelectedRestaurant();
            selectedRestaurantProduct();
        };
    }, []);

    const { restaurant } = selectedRestaurant;
    return (
        <>
            {width < 635 && filterModal && (
                <LeftMenuComponent
                    customCategories={selectedRestaurantProductArr}
                    top={window.pageYOffset - 100}
                />
            )}
            <Spin spinning={localLoading}>

                <>
                    <ModalWrapper
                        height={window.pageYOffset - 170}
                        showModal={showModal}
                        closeModal={showModalRegularMenu}
                    >
                        <PopUP height={window.pageYOffset + 250} />
                    </ModalWrapper>

                    <div className="selected_restaurant_wrapper">
                        {!!restaurant && (
                            <>
                                <RestaurantTitle name={restaurant.name} />
                                <RestaurantInfo restaurant={restaurant} width={width} />
                                {width < 650 && (
                                    <div className="filter_for_mobile">
                                        <span onClick={showFilterModal}>
                                            {" "}
                                            <img src={filterPng} alt="Filter" />
                                            Filter
                                        </span>
                                    </div>
                                )}
                                <div className="selected_restaurant_body_wrapper">
                                    {width > 635 && (
                                        <LeftMenuComponent
                                            customCategories={selectedRestaurantProductArr}
                                        />
                                    )}
                                    <div className="selected_restaurant_product_wrapper">
                                        {!!selectedRestaurantProductArr.length &&
                                            selectedRestaurantProductArr.map((item, index) => {
                                                let product = null;
                                                if (!item?.products || item?.products?.length === 0) {
                                                    return null;
                                                }
                                                if (filter.selectedCategories.length === 0) {
                                                    product = (
                                                        <RestaurantsProducts key={index} item={item} />
                                                    );
                                                }
                                                if (
                                                    filter.selectedCategories.filter(
                                                        (i) => i === item.slug
                                                    ).length
                                                ) {
                                                    product = (
                                                        <RestaurantsProducts key={index} item={item} />
                                                    );
                                                }
                                                return product;
                                            })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </>
            </Spin>
        </>
    );
};
const mapStateToProps = (state) => ({
    selectedRestaurant: state.selectedRestaurant,
    localLoading: state.localLoading,
    selectedRestaurantProductArr: state.selectedRestaurantProduct,
    showModal: state.regularMenu.showModal,
    filter: state.filter,
    width: state.width,
    filterModal: state.filterModal,
    isAuth: state.user.isAuth,
});

const mapDispatchToProps = {
    updateSelectedRestaurant,
    selectedRestaurantProduct,
    showModalRegularMenu,
    showFilterModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectedRestaurant);
