import React, { useEffect } from 'react';
import { addFirstItem } from '../../actions/basketActions';
import { connect } from "react-redux";
import "../../styles/ShoppingCart/ShoppingCart.scss"
import { changeStep } from '../../actions/checkoutAction';
import { useHistory } from "react-router-dom";
import user from "../../img/userIcon.png"
import { showModal } from "./../../actions/baseActions";
import ModalTypes from '../../enums/Modal.Types.Enum';

const OrderSummary = ({ basket, isAuth, showModal, addFirstItem, tip = 0, donate = 0, isDeliveryStatus }) => {
    const history = useHistory()

    const { items = [], subTotal, deliveryFee, serviceFee, tax, total } = basket;
    const finalTotal = isDeliveryStatus ? +total : total - deliveryFee;

    useEffect(() => {
        if (!basket?.items?.length) {
            addFirstItem()
        }
    }, [basket?.items?.length]);

    const getRound = (price) => {
        return (Math.round(price * 100) / 100).toFixed(2)
    }

    return (
        <div className='order_summary_wrapper' >
            {!isAuth && <div className='login_checkout_faster' onClick={() => showModal(ModalTypes.SIGN_IN_MODAL)}>
                <img src={user} alt="user" /><span>Login and checkout faster</span>
            </div>}
            <div className='sc_order_summary' >
                <div>
                    Order Summary
                </div>
                {items.map((i, k) => (
                    <span
                        key={k}
                        style={{ borderBottom: "1px solid #EBEBEB", paddingBottom: "16px", fontWeight: 700 }}
                    >
                        {i.count} {i.product.name}
                        <span> ${(Math.round(i.price * 100) / 100).toFixed(2)}</span>
                    </span>))}
                <span style={{ fontWeight: 700 }}>Subtotal<span> ${getRound(subTotal)}</span></span>
                {isDeliveryStatus && <span>Delivere fee <span> {deliveryFee === 0 ? "$0.00" : `$${getRound(deliveryFee)}`}</span></span>}
                <span>Service fee <span> {serviceFee === 0 ? "$0.00" : `$${getRound(serviceFee)}`}</span></span>
                <span>Tax <span> {tax === 0 ? "$0.00" : `$${getRound(tax)}`}</span></span>
                {!tip ? null : <span>Tip <span>${getRound(tip)}</span></span>}
                {!donate ? null : <span>Donation <span>${getRound(donate)}</span></span>}
                <span style={{ fontWeight: 700 }}>You pay <span>${getRound(+tip + finalTotal + +donate)}</span> </span>
                <button onClick={() => { history.push(`/restaurant-menu/${basket.restaurant.slug}`) }}>Add item</button>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    basket: state.basket,
    isAuth: state.user.isAuth
});

const mapDispatchToProps = {
    changeStep,
    addFirstItem,
    showModal
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OrderSummary);
