import React, { memo } from 'react';
import { connect } from "react-redux";
import { showModalRegularMenu, setSelectedProduct, getProducts, updateSelectedRestaurant, changeLocalRestaurantIsFavorites } from "../../../actions/baseActions";
import ReviewsMaker from '../../../container/ReviewsMaker/ReviewsMaker';
import { useHistory } from "react-router-dom";
import heart from "./../../../img/heart.png";
import redHeart from "./../../../img/redHeart.png";
import { addFavoritesProduct, removeFavoritesProduct } from "./../../../api/Product";
const RestaurantItem = memo(({ item, isDelivery, key, items, updateSelectedRestaurant, isAuth, removeFavoriteProduct = () => { }, changeLocalRestaurantIsFavorites }) => {
    const history = useHistory()
    const changeFavorites = (slug, type) => {
        let localItems = items.map(element => {
            if (element.slug === slug) {
                return {
                    ...element,
                    isFavorite: type === "add" ? true : false
                }
            }
            return element
        })
        if (type === "add") {
            addFavoritesProduct(slug, 'restaurant').then(data => {
                if (data.success) {
                    changeLocalRestaurantIsFavorites(localItems)
                }
            })
        }
        if (type === "remove") {

            removeFavoritesProduct(slug).then(data => {
                if (data.success) {
                    removeFavoriteProduct()
                    changeLocalRestaurantIsFavorites(localItems)
                }
            })
        }
    }

    const setSelectedRestaurant = (slug) => {
        updateSelectedRestaurant(slug).then(success => {
            if (success) {
                history.push(`/restaurant-menu/${item.slug}`)
            }
        })
    }

    return (
        <div className={'item_wrapper'} key={key} >
            <div className="item">
                {isAuth &&
                    <img
                        src={!item?.isFavorite ? heart : redHeart}
                        className="heart"
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            cursor: 'pointer',
                            transition: '.2s ease-in-out'
                        }}
                        alt={"heart"}
                        onClick={() => {
                            if (!item?.isFavorite) {
                                changeFavorites(item.slug, "add")
                            }
                            if (item?.isFavorite) {
                                changeFavorites(item.slug, "remove")
                            }
                        }} />
                }
                <div
                    onClick={() => { setSelectedRestaurant(item?.slug) }}
                    className={"itemImage"}
                    style={{ backgroundImage: `url(${item?.downloadLink})` }}
                ></div>
                <div className='item_content'>
                    <p onClick={() => { setSelectedRestaurant(item?.slug) }}>{item?.name}</p>
                    <div className='location' ><span >{item?.address?.address} / {item?.distance}</span></div>
                    <div className="stars" >
                        <ReviewsMaker
                            rating={item.rating}
                            count={item.reviewsCount}
                        />
                    </div>
                    <div className='item_about'>
                        {isDelivery && <span>
                            ${item.deliveryMinimum} Min & up <b>${item.productPriceAVG}</b>
                            Delivery fee
                        </span>}
                        {!isDelivery && <span style={{ width: "110px" }}></span>}
                        <button onClick={() => { setSelectedRestaurant(item.slug) }}> View </button>
                    </div>
                </div>
            </div>
        </div>)
})
const mapStateToProps = state => ({
    isAuth: state.user.isAuth,
    filter: state.filter,
    pageCount: state.pageCount,
    items: state.restaurants.items,
    isDelivery: state.isDelivery
});

const mapDispatchToProps = {
    showModalRegularMenu,
    setSelectedProduct,
    getProducts,
    changeLocalRestaurantIsFavorites,
    updateSelectedRestaurant
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RestaurantItem);