import React, { memo } from 'react';
import { connect } from "react-redux";
import ReviewsMaker from './../../../container/ReviewsMaker/ReviewsMaker';
import basket from "./../../../img/basket.svg";
import heart from "./../../../img/heart.png";
import redHeart from "./../../../img/redHeart.png";
import {
    changeLocalIsFavorites,
    getProducts,
    setSelectedProduct,
    showLocalLoading,
    showModalRegularMenu
} from "../../../actions/baseActions"
import { getSingleProductDetails } from '../../../api/Home.API';
import { addFavoritesProduct, removeFavoritesProduct } from '../../../api/Product';

const ProductItem = memo((props) => {
    const {
        item,
        setSelectedProduct,
        showLocalLoading,
        removeFavoriteProduct = () => { },
        showModalRegularMenu,
        key,
        isAuth,
        items = [],
        changeLocalIsFavorites,
    } = props

    const changeFavorites = (slug, type) => {
        let localItems = items.map(element => {
            if (element.slug === slug) {
                return {
                    ...element,
                    isFavorite: type === "add" ? true : false
                }
            }
            return element
        })
        changeLocalIsFavorites(localItems)
        if (type === "add")
            addFavoritesProduct(slug)
        if (type === "remove") {
            removeFavoritesProduct(slug).then(() => {
                removeFavoriteProduct()
            })
        }
    }

    const openPopUp = (slug) => {
        showLocalLoading(true)
        getSingleProductDetails(slug).then(({ data }) => {
            setSelectedProduct(data)
            showLocalLoading(false)
            showModalRegularMenu();
        })
    }

    return (
        <div className={'item_wrapper'} key={key}>
            <div className="item">
                {isAuth && <img src={!item?.isFavorite ? heart : redHeart} className="heart" alt={"heart"} onClick={() => {
                    changeFavorites(item.slug, item?.isFavorite ? 'remove' : 'add')
                }} />}
                <div onClick={() => openPopUp(item.slug)}
                    className={"itemImage"} style={{ backgroundImage: `url(${item?.downloadLink})` }}></div>
                <div className='item_desc'>
                    <span className='item_category'>{item?.productCategoriesLine}</span>
                    <p className='item_name' onClick={() => openPopUp(item.slug)}>
                        {item?.name}
                    </p>
                    <div className="stars">
                        <ReviewsMaker rating={item.rating} count={item.reviewsCount} />
                    </div>
                    <span className='item_restaurant_name'>By<span> {item?.restaurant?.name}</span></span>
                    <div className='item_footer'>
                        <div className="price">${Math.min(...items.map(({ price }) => price))}</div>
                        <button onClick={() => openPopUp(item.slug)}>
                            <img src={basket} alt="basket" />Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
},
    (prevProps, nextProps) => {
        return JSON.stringify(prevProps) === JSON.stringify(nextProps)
    })

const mapStateToProps = state => ({
    isAuth: state.user.isAuth,
    filter: state.filter,
    pageCount: state.pageCount,
    items: state.products.items,

});

const mapDispatchToProps = {
    showModalRegularMenu,
    setSelectedProduct,
    getProducts,
    changeLocalIsFavorites,
    showLocalLoading
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductItem);