import React, {Component} from 'react';
import {Form, Input, Button} from 'antd';
import {connect} from 'react-redux';
import { loginUser } from "../../../actions/userActions";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {showModal} from '../../../actions/baseActions';
import ModalTypes from "../../../enums/Modal.Types.Enum";


class SignInForm extends Component {
    handleSubmit = values => {
      // Using action version
      this.props.loginUser({
          username: values.username.trim().toLowerCase(),
          password: values.password
      }).then(result => {
          if (result) {
              this.props.onSuccessSignIn(result);
              this.props.showModal(ModalTypes.SIGN_IN_MODAL, false);
          }
      });
    };

    render() {
        return (
            <Form
              name='sign_in_form'
              onFinish={this.handleSubmit}
              className="login-form"
              layout={'vertical'}
            >
                <Form.Item
                style={{margin:"0 auto",width:"350px"}}
                    label={'Username'}
                    name={"username"}
                    fieldKey={"username"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Username!"
                      }
                    ]}
                >
                  <Input style={{textTransform: 'lowercase'}} autoComplete={'username'}/>
                </Form.Item>

                <Form.Item
                style={{margin:"0 auto",width:"350px"}}
                    label={'Password'}
                    name={"password"}
                    fieldKey={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!"
                      }
                    ]}
                >
                  <Input autoComplete={'current-password'} type='password'/>
                </Form.Item>

                <div className={'LoginForgot'}>
                    <Link to={'#'} onClick={this.props.onForgotPasswordClick} className="login-form-forgot">
                        Forgot password
                    </Link>
                </div>

                <Button htmlType="submit" className="login-form-button">
                    Login
                </Button>

                <p className={'DontHaveAccount'}>
                  Don't have an account ?
                  <Link to={'#'} onClick={this.registerUser}> Register</Link>
                </p>
            </Form>
        );
    }
  registerUser = () => {
    this.props.showModal(ModalTypes.SIGN_IN_MODAL, false);
    this.props.showModal(ModalTypes.REGISTRATION_MODAL);
  }
}

SignInForm.propTypes = {
    onForgotPasswordClick: PropTypes.func.isRequired,
    onRegisterClick: PropTypes.func.isRequired,
    onSuccessSignIn: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  loginUser,
  showModal
};

export default withRouter(connect(
    null,
    mapDispatchToProps,
)(SignInForm));
