
import { BASKET } from "./_actionTypes";
import { getBasket, clearBasket } from "./../api/Basket.API";

export const addFirstItem = () => (dispatch) => {
	return getBasket().then(({ data }) => {
		dispatch({
			type: BASKET.ADD_FIRST_ITEM,
			data
		})
		if (data.items.length) localStorage.setItem('restaurantSlug', data.restaurant?.slug)
		else localStorage.removeItem('restaurantSlug')
		return data
	})
};

export const updateBagItem = (item = {}, restaurant = {}) => (dispatch) => {
	dispatch({
		type: BASKET.UPDATE_ITEM,
		item: item,
		restaurant: restaurant
	})
};

export const addNewProduct = (product = {}) => dispatch => {
	dispatch({
		type: BASKET.ADD_NEW_PRODUCT,
		product: product
	})
}

export const removeBasketItem = (productKey) => dispatch => {
	dispatch({
		type: BASKET.REMOVE_ITEM,
		productKey: productKey
	});
};

export const removeLocalBasketItem = (data) => dispatch => {
	dispatch({
		type: BASKET.REMOVE_LOCAL_ITEM,
		payload: data
	});
};


export const changeItemCount = (productKey, count) => dispatch => {
	dispatch({ type: BASKET.CHANGE_COUNT, payload: { productKey, count } })
};

export const calculateBasket = () => dispatch => {
	dispatch({ type: BASKET.CALCULATE_BASKET });
};

export const clearBasketAction = () => dispatch => {
	return clearBasket().then(() => {
		dispatch({ type: BASKET.CLEAR_CART });
		return true
	})
};
