import "whatwg-fetch";

import Config from "Config";

import { updateAccessToken } from "../services/Security.Services";
import { getAuthData, getStoredUserLocation } from "../services/User.Service";
const BaseUrl = Config.ServerUrl;

export const HandleResponseState = (result) => (result ? result : false);

export const StringifyUrlEncoded = (obj) => {
  let urlEncoded = "";
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      if (Array.isArray(obj[key]) && obj[key].length) {
        // eslint-disable-next-line no-loop-func
        obj[key].map((_i, j) => {
          return (urlEncoded +=
            "&" +
            encodeURIComponent(key) +
            "[]" +
            "=" +
            encodeURIComponent(obj[key][j]));
        });
      }
      if (Array.isArray(obj[key]) && obj[key].length === 0) {
        continue;
      } else if (!Array.isArray(obj[key]) && obj[key] !== "") {
        urlEncoded +=
          "&" + encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
      }
    }
  }
  urlEncoded = urlEncoded.replace(/^&/, "");
  return urlEncoded;
};

export const Post = (
  url,
  headers,
  body,
  authorized = false,
  plainBody = false
) => {
  const authData = getAuthData();
  if (authorized) {
    if (authData && authData.token) {
      headers.Authorization = "Bearer " + authData.token;
    }
    if (!headers.Accept) {
      headers.Accept = "application/json, */*";
    }
  } else {
    headers.DeviceId = authData.DeviceId;
  }
  // store user current location
  const locationData = getStoredUserLocation();
  if (locationData && "lat" in locationData && "log" in locationData) {
    headers["UserLocation"] = locationData.lat + "|" + locationData.log;
  }

  return fetch(BaseUrl + "/" + url, {
    method: "POST",
    headers,
    body: plainBody ? body : JSON.stringify(body).toString(),
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        return updateAccessToken().then(async (refreshResult) => {
          if (refreshResult) {
            return await Post(url, headers, body, authorized, plainBody);
          } else {
            document.dispatchEvent(new CustomEvent("unauthorizedOperation"));
          }
          return false;
        });
      }

      if (!response.ok) {
        return response.status === 409;
      }
      // This is just a OPTIONS call to the server to make sure that the request is OK
      // Here can be done some manual checks.
      return response.json();
    })
    .catch((data) => {
      console.log("Error", data);
    });
};

export const Delete = (
  url,
  headers,
  body,
  authorized = false,
  plainBody = false
) => {
  const authData = getAuthData();
  if (authorized) {
    if (authData && authData.token) {
      headers.Authorization = "Bearer " + authData.token;
    }
  }
  headers.DeviceId = authData.DeviceId;
  // store user current location
  const locationData = getStoredUserLocation();
  if (locationData && "lat" in locationData && "log" in locationData) {
    headers["UserLocation"] = locationData.lat + "|" + locationData.log;
  }

  return fetch(BaseUrl + "/" + url, {
    method: "DELETE",
    headers,
    body: plainBody ? body : JSON.stringify(body).toString(),
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        // Refresh Access Token
        return updateAccessToken().then(async (refreshResult) => {
          if (refreshResult) {
            return await Delete(url, headers, body, authorized, plainBody);
          } else {
            document.dispatchEvent(new CustomEvent("unauthorizedOperation"));
          }
          return false;
        });
      }
      // This is just a OPTIONS call to the server to make sure that the request is OK
      // Here can be done some manual checks.
      return response.json();
    })
    .catch((data) => {
      console.log("Error", data);
    });
};

export const Get = (
  url,
  headers = {},
  authorized = false,
  isDelivery = true
) => {
  const authData = getAuthData();
  if (authorized) {
    if (authData && authData.token) {
      headers.Authorization = "Bearer " + authData.token;
    }
  }
  headers.DeviceId = authData.DeviceId;

  if (!headers.Accept) {
    headers.Accept = "application/json, */*";
  }

  // store user current location
  const locationData = getStoredUserLocation();
  if (
    isDelivery &&
    locationData &&
    "lat" in locationData &&
    "log" in locationData
  ) {
    headers["UserLocation"] = locationData.lat + "|" + locationData.log;
  } else {
    headers["UserLocation"] = "";
  }

  return fetch(BaseUrl + "/" + url, {
    method: "GET",
    headers,
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        // Refresh Access Token
        return updateAccessToken().then(async (refreshResult) => {
          if (refreshResult) {
            return await Get(url, headers, authorized);
          } else {
            document.dispatchEvent(new CustomEvent("unauthorizedOperation"));
          }
          return false;
        });
      }
      // This is just a OPTIONS call to the server to make sure that the request is OK
      // Here can be done some manual checks.
      return response.json();
    })
    .catch((data) => {
      if (!data.ok && data.status === 401) {
        // Refresh Access Token
        return updateAccessToken().then(async (refreshResult) => {
          if (refreshResult) {
            return await Get(url, headers, authorized);
          } else {
            document.dispatchEvent(new CustomEvent("unauthorizedOperation"));
          }
          return false;
        });
      }
      console.log("Error", data);
    });
};

export const Put = (url, headers, body, authorized = false) => {
  const authData = getAuthData();
  if (authorized) {
    if (authData && authData.token) {
      headers.Authorization = "Bearer " + authData.token;
    }
  }

  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }
  headers.DeviceId = authData.DeviceId;
  // store user current location
  const locationData = getStoredUserLocation();
  if (locationData && "lat" in locationData && "log" in locationData) {
    headers["UserLocation"] = locationData.lat + "|" + locationData.log;
  }
  return fetch(BaseUrl + "/" + url, {
    method: "PUT",
    headers,
    body: JSON.stringify(body).toString(),
  })
    .then((response) => {
      if (!response.ok && response.status === 401) {
        // Refresh Access Token

        return updateAccessToken().then(async (refreshResult) => {
          if (refreshResult) {
            return await Put(url, headers, body, authorized);
          } else {
            document.dispatchEvent(new CustomEvent("unauthorizedOperation"));
          }
          return false;
        });
      }

      if (!response.ok) {
        return response.status === 409;
      }

      // This is just a OPTIONS call to the server to make sure that the request is OK
      // Here can be done some manual checks.
      return response.json();
    })
    .catch((data) => {
      console.log("Error", data);
    });
};
