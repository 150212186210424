import React from 'react';
import { connect } from "react-redux"
import { useEffect, useState } from "react";
import { getList } from '../../api/CreditCard.API';
import { cardIcons } from '../../enums/Card.Icons.Enum';
import { cc_expires_format } from '../../settings/cardDateExpreshion';

const CreditCard = ({ isAuth, getCardsInfo, creditcard }) => {
    const [creditCards, setCreditCards] = useState([]);
    const [addNewCard, setAddNewCard] = useState(false);

    useEffect(() => {
        if (!isAuth) setAddNewCard(true)
    }, [isAuth]);

    useEffect(() => {
        if (isAuth) {
            getList().then((data) => {
                if (data?.data?.length) {
                    setCreditCards([...data.data])
                } else if (!data?.data?.length) {
                    setAddNewCard(true)
                }
            })
        }
    }, []);

    const changeCardValues = (value, type, creditcard = {}) => {
        getCardsInfo(value, type, creditcard)
    }

    const changeCardNumber = (value) => {
        let a = value;
        const reg = new RegExp('^[0-9 ]+$');
        if (!reg.test(a)) {
            return false
        }
        else {
            if (a.slice(0, 2) === "34" || a.slice(0, 2) === "37") {
                if (a.length < 18) {
                    if (a.length === 3 || a.length === 10) {
                        a = a + " ";
                        changeCardValues(a, "showNumber")
                    } else {
                        changeCardValues(a, "showNumber")
                    }
                }
            } else {
                if (a.length === 4 || a.length === 9 || a.length === 14) {
                    a = a + " ";
                    changeCardValues(a, "showNumber")
                } else {
                    changeCardValues(a, "showNumber")
                }
            }
        }
    }


    return (<div className='payment_wrapper'>
        {isAuth && creditCards.length ? <>  <p className='payment_header' >Payment</p>
            <div className='cards_wrapper'> {creditCards.map(card => {
                return <span
                    className={creditcard.showNumber === card.showNumber ? 'card_wrapper default ' : "card_wrapper"}
                    key={card.id}
                    onClick={() => {
                        setAddNewCard(false)
                        getCardsInfo("", "", { ...card })
                    }}
                >
                    <img alt="card icon" src={cardIcons['unknown']} />
                    <span>{card.showNumber}</span>
                    <span>{card.holderName}</span>
                </span>
            })}
                <span className='card_wrapper add_new' onClick={() => {
                    setAddNewCard(!addNewCard)
                    getCardsInfo("", "", {
                        expDate: "",
                        showNumber: "",
                        holderName: "",
                        cvv: "",
                        save: false
                    })
                }}>
                    Add new one
                </span>
            </div></> : null}
        {addNewCard
            &&
            <div className='add_new_card_wrapper'>
                <div className='add_new_card_item'>
                    <p>Card Number</p>
                    <input
                        type='text'
                        value={creditcard.showNumber}
                        onKeyDown={e => {
                            if (e.key === "Backspace" && creditcard.showNumber.length === 1) {
                                changeCardValues("", "showNumber")
                            }
                            if (e.key === "Backspace" && creditcard.showNumber.substr(-1) === " ") {
                                changeCardValues(creditcard.showNumber.slice(0, creditcard.showNumber.length - 1), "showNumber")
                            }
                        }}
                        onChange={e => changeCardNumber(e.target.value)}
                        maxLength={19}
                        placeholder={"0000 0000 0000 0000"}
                    />

                </div>
                <div className='add_new_card_item'>
                    <p>Name on Card</p>
                    <input
                        type="text"
                        value={creditcard.holderName}
                        onKeyDown={e => {
                            if (e.key === "Backspace" && creditcard.holderName.length === 1) {
                                changeCardValues("", "holderName")
                            }
                        }}
                        onChange={e => {
                            if (/^[A-z ]+$/.test(e.target.value)) {
                                changeCardValues(e.target.value, "holderName")
                            }
                        }}
                        placeholder={"Firstname Lastname"}
                    />
                </div>
                <div className='add_new_card_item'>
                    <p>Exp Date</p>
                    <input
                        type="text"
                        value={creditcard.expDate}
                        maxLength={5}
                        onKeyDown={e => {
                            if (e.key === "Backspace" && creditcard.expDate.length === 1) {
                                changeCardValues("", "expDate")
                            }
                            if (e.keyCode === 8 && creditcard.expDate.substr(-1) === "/") {
                                changeCardValues(creditcard.expDate.slice(0, 2), "expDate")
                            }
                        }}
                        onChange={e => changeCardValues(cc_expires_format(e.target.value), "expDate")}
                        placeholder={"MM/YY"}
                    />
                </div>
                <div className='add_new_card_item'>
                    <p>CVV</p>
                    <input
                        type="number"
                        placeholder={"123"}
                        maxLength={3}
                        value={creditcard.cvv}
                        onChange={e => {
                            if (e.target.value.match("^[0-9]*$")) {
                                if (e.target.value.length > 3) {
                                    return false
                                } else {
                                    changeCardValues(e.target.value, "cvv")
                                }
                            }
                        }}
                    />
                </div>
                <div className='sp_option_checkbox' onClick={(e) => changeCardValues(e.target.checked, "save")}>
                    <input type='checkbox' name='checkbox' value={creditcard.save} />
                    <label htmlFor="checkbox">Save card info</label>
                </div>
            </div>
        }
    </div>)

}
const mapStateToProps = (state) => ({
    isAuth: state.user.isAuth,
});

export default connect(
    mapStateToProps
)(CreditCard);
