import React from 'react';
import "../../../styles/Dashboard/Dashboard.scss"
import { useState, useEffect } from "react";
import camera from "../../../img/camera.png"
import { sendUpdateUser, changePassword, sendUserPhoto } from '../../../api/User.API';
import ModalWrapper from '../../ModalWrapper/ModalWrapper';
import { showLocalLoading } from '../../../actions/baseActions';
import { useDispatch } from 'react-redux';
import { messageType, showMessage } from "../../../services/Utilities";

const Settings = ({ user }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, []);

    const dispatch = useDispatch();
    const { lastName, firstName, phone, email, avatar } = user
    const [userSettings, setUserSettings] = useState({
        lastName, firstName, phone, email, avatar
    });

    useEffect(() => {
        setUserSettings({ ...userSettings, avatar: user.avatar })
    }, [user.avatar]);

    const [showModal, setShowModal] = useState(false)

    const [passwords, setPasswords] = useState({
        currentPassword: "",
        newPassword: "",
        repeatPassword: ""
    });

    const onHandleChange = (settingType, value) => {
        if (value.length < 12) {
            setUserSettings({
                ...userSettings,
                [settingType]: value
            })
        }
    }

    const chengeAvatarImage = (value) => {
        let formData = new FormData()
        formData.append("avatar", value)
        sendUserPhoto(formData).then(data => {
            if (data.success) {
                return setUserSettings({
                    ...userSettings, avatar: data.data.avatar
                })
            }
        })
    }

    const onHandleSubmit = () => {
        let data = {
            firstName: userSettings.firstName,
            lastName: userSettings.lastName,
            phone: userSettings.phone
        }
        dispatch(showLocalLoading(true))
        sendUpdateUser(data).then(() => {
            dispatch(showLocalLoading(false))
        })
    }

    const ChangePasswordInput = (value, type) => {
        setPasswords({
            ...passwords,
            [type]: value
        })
    }

    const changePasswordsHandler = () => {
        const { currentPassword, newPassword, repeatPassword } = passwords
        if (currentPassword.length > 0 && newPassword.length > 0 && repeatPassword.length > 0 && newPassword === repeatPassword) {
            changePassword(passwords).then(_ => {
                showMessage(messageType.success, "Success!");
                setShowModal(false)
            })
        }
    }

    const PasswordChangeModal = () => {
        return <div className='change_password_wrapper'
            style={{ top: window.pageYOffset + 250 }}
        >
            <div>Change Password</div>
            <div>
                <span>Current Password</span>
                <input
                    value={passwords.currentPassword}
                    type={'password'}
                    onChange={(e) => {
                        ChangePasswordInput(e.target.value, "currentPassword")
                    }} />
            </div>
            <div>
                <span>New Password</span>
                <input
                    value={passwords.newPassword}
                    type={'password'}
                    onChange={(e) => {
                        ChangePasswordInput(e.target.value, "newPassword")
                    }} />
            </div>
            <div>
                <span>Repeat Password</span>

                <input
                    value={passwords.repeatPassword}
                    type={'password'}
                    onChange={(e) => {
                        ChangePasswordInput(e.target.value, "repeatPassword")
                    }} />
            </div>

            <button onClick={changePasswordsHandler}>Change Password</button>
        </div>
    }
    
    return <div className='settings_wrapper'>
        <div className='settings_header'>
            <div className='avatar_wrapper'>
                <img className='avatar' src={userSettings.avatar} width={"181px"} height={"181px"} alt="avatar" />
                <div className="image-upload change_avatar">
                    <label htmlFor="file-input">
                        <img src={camera} alt="camera" style={{ pointerEvents: "none" }} />
                    </label>
                    <input
                        id="file-input"
                        type="file"
                        onChange={(e) => {
                            chengeAvatarImage(e.target.files[0])
                        }}
                    />
                </div>
            </div>
        </div>

        <div className='setting_inputs_area'>
            <div>
                <label>Name</label>
                <input
                    value={userSettings.firstName}
                    type={"text"}
                    placeholder={firstName}
                    onChange={(e) => !(e.target.value[e.target.value.length - 1] / 2) && onHandleChange('firstName', e.target.value)}
                />
            </div>
            <div>
                <label>Surename</label>
                <input
                    value={userSettings.lastName}
                    type={"text"}
                    placeholder={lastName}
                    onChange={(e) => !(e.target.value[e.target.value.length - 1] / 2) && onHandleChange('lastName', e.target.value)}
                />
            </div>
            <div>
                <label>Email*</label>
                <input
                    value={userSettings.email}
                    type={"email"}
                    placeholder={email}
                    disabled={true}
                    onChange={(e) => {
                        onHandleChange('email', e.target.value)
                    }}
                />
            </div>
            <div>
                <label>Phone*</label>
                <input
                    value={userSettings.phone}
                    type={"number"}
                    placeholder={'Phone number'}
                    onChange={(e) => onHandleChange('phone', e.target.value)}
                />
            </div>
            <div className=''>
                <label>Password*</label>
                <div
                    className='input_div'
                    onClick={() => setShowModal(true)}
                >******</div>

            </div>
            <button
                className={'active'}
                onClick={onHandleSubmit}
            >Edit</button>
        </div>
        <ModalWrapper
            height={window.pageYOffset - 170}
            showModal={showModal}
            closeModal={() => setShowModal(false)}
        >
            {PasswordChangeModal()}
        </ModalWrapper>
    </div>
}
export default Settings;