import React from 'react'
import PropTypes from "prop-types";
import Config from "Config";
import DEFAULT_AVATAR from '../../img/user.png';
import {Tooltip} from 'antd';
const ImgServer = ({src = '', pathPrefix = Config.ServerUrl, className, id, title, onClick, alt, isLocale = false, isAvatar = false, style, tooltip = false}) => {
    if(/^data:image\/(png|jpeg|gif);base64.*$/.test(src)){
        isLocale = true;
    }
    
    let url = isLocale ? src : (src.startsWith(Config.ServerUrl) ? src : `${pathPrefix}${src}`);
    url = isAvatar && src === '' ? DEFAULT_AVATAR : url;

    const img =
        url && (<img
            src={url}
            id={id}
            className={className}
            alt={alt}
            title={title}
            style={style}
            onClick={onClick}
        />);

    return (
        <Tooltip {...tooltip}>
            {img}
        </Tooltip>
    );
};

ImgServer.propTypes = {
    src: PropTypes.string,
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    alt: PropTypes.string,
    isLocale: PropTypes.bool,
    isAvatar: PropTypes.bool,
    style: PropTypes.object,
    tooltip: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
};

export default ImgServer;
