import React from 'react';
import Modal from "../HelpersComponents/Modal.Component";
import PropTypes from "prop-types";
import { showLoading } from "../../actions/baseActions";
import { connect } from "react-redux";
import { Button, Input, Form, Spin } from 'antd';
import { requestVerificationCode } from '../../services/User.Service'
import { showModal } from '../../actions/baseActions';
import ModalTypes from "../../enums/Modal.Types.Enum";

export const ForgotPasswordModal = ({ forgotPasswordModalVisible, showModal, loading, showLoading }) => {
	const handleSubmit = values => {
		if ('email' in values) {
			showLoading(true);
			requestVerificationCode(values.email).then(result => {
				if (result) {
					showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false)
					localStorage.setItem("Email",values.email)
				}
			}).finally(() => {
				showModal(ModalTypes.CHANGE_PASSWORD_MODAL)
				showLoading(false);
			});
		}
	};

	return (
		<Modal
			className={"SignIn forgot_password"}
			visible={forgotPasswordModalVisible}
			onCancel={() => showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false)}
		>
			<Spin spinning={loading}>
				<h2 className={'SignInTitle text-center'}>Forgot password</h2>
				<h6 className='forgot_password_desc'>
					Enter the email or phone number associated with your account and we’ll send a verification code to reset your password.
					Hide
					Phone number, user name, or email address
					Hide
					Error message
					Send</h6>
				<div>
					<Form
						onFinish={handleSubmit}
						className="login-form "
						name={'forgot_password_request'}
					>
						<Form.Item
							label={'Phone number, user name, or email address'}
							name={'email'}
							rules={[{
								required: true,
								message: 'You must provide either a valid username or an email.'
							}]}
						>
							<Input />
						</Form.Item>
						<Button type="primary" htmlType="submit" className="login-form-button">
							Send
						</Button>
					</Form>
				</div>
			</Spin>
		</Modal>
	)
};

ForgotPasswordModal.propTypes = {
	forgotPasswordModalVisible: PropTypes.bool.isRequired,
	showModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	forgotPasswordModalVisible: state.modalVisible.forgotPassword,
	loading: state.loading
});

const mapDispatchToProps = {
	showModal,
	showLoading
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ForgotPasswordModal);

//TODO check if this component is used.