import { DefaultFilterData, DefaultFilterItems } from '../enums/Base.Enum';
import { getStoredUserLocation } from "../services/User.Service";
import { getBasket } from "../services/Basket.Service";
import * as BasketService from "../services/Basket.Service";

const isDelivery = () => {
    return sessionStorage.getItem('isDelivery');
};

const address = () => {
    const location = getStoredUserLocation()
    if (location) {
        return location
    }
    return {}
}

const isCatering = () => {
    const isCatering = JSON.parse(localStorage.getItem('isCatering'));
    return isCatering === true;
}

const basketState = () => {
    const localBasket = getBasket();
    const basket = {
        subtotal: 0,
        delivery: 0,
        deliveryMinimum: 0,
        productCount: 0,
        deliveryTime: {
            min: undefined,
            max: undefined
        },
        singleBrunch: undefined
    }
    if (localBasket && localBasket.restaurant && localBasket.products) {
        return BasketService.calculateBasket({
            products: localBasket.products,
            restaurant: localBasket.restaurant,
            isCatering: localBasket.isCatering
        })
    }
    return basket
}

export default {
    user: {
        isAuth: false,
        data: {},
    },
    currentLocation: {
        address: address(),
        isAddressSet: false
    },
    basket: basketState(),
    categories: [],
    restaurants: {
        items: []
    },
    reviews: {
        data: [],
        hasMore: false
    },
    products: [],
    cateringProducts: {},
    modalVisible: {
        basket: false,
        notification: false,
        signIn: false,
        registration: false,
        forgotPassword: false,
        map: false,
        changePassword: false,
        modalProps: {},
        locationModal: false,
    },
    filter: DefaultFilterItems,
    defaultFilterData: DefaultFilterData,
    showHeader: true,
    selectedRestaurant: {},
    selectedRestaurantProduct: [],
    leftMenuVisible: false,
    loading: false,
    localLoading: false,
    isDelivery: isDelivery(),
    isCatering: isCatering(),
    notification: {
        messages: []
    },
    regularMenu: {
        selectedProduct: {},
        showModal: false,
    },
    checkout: {
        userInfo: {},
        donate: false,
        checkoutStep: 1,
        uuidCode: "",
        schedule: {
            schedule: false,
            scheduleDate: {
                time: "",
                date: ""
            }
        }
    },
    selectedRestaurantFilter: [],
    selectedSettings: "settings",
    pageCount: 1,
    pageCountRestaurant: 1,
    orderHistory: [],
    width: 1080,
    filterModal: false
}
