import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";

export const BasketEmptyInfoModal = (clearBasket, handleLocationChange) => (
  Modal.info({
    title: 'You need to empty basket to change your current location...',
    icon: <InfoCircleOutlined style={{color: '#ffbf00'}}/>,
    maskClosable: true,
    okText: 'Empty',
    onOk: () => {
        clearBasket();
        handleLocationChange()
    }
  })
);