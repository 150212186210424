import React, { useCallback } from "react";
import { useState, useEffect, useRef } from "react";
import trash from "../../../img/trash.png";
import close from "../../../img/close.png";
import { addressFormatter } from "../../../services/Utilities";
import { updateLocation } from "../../../actions/locationActions";
import { getAddressFromLatLng } from "../../HelpersComponents/MapComponents/Map.Component";
import Addresses from "../../HelpersComponents/Add.Address.Component";
import { connect, useSelector } from "react-redux";
import { showLocalLoading } from "../../../actions/baseActions";
import { addClientAddress, changeClientAddress, deleteClientAddress, editClientAddress, getClientAddress } from "../../../api/Address.API";
import ModalWrapper from "../../ModalWrapper/ModalWrapper";
import { OrderDeliveryType } from "../../../enums/Order.Status.Enum";
import { useLocation } from "react-router";
import { updateOrderDeliveryType } from "../../../actions/orderActions";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const AddressesSettings = ({
    inputPlaceholder = "Enter Your Address",
    onAddressSelected = (f) => f,
    localLoading,
    updateOrderDeliveryType = (f) => f,
    showLocalLoading,
}) => {
    const currentLocation = useSelector((state) => state.currentLocation?.address);
    const { pathname } = useLocation();
    const [emailValue, setEmailValue] = useState("");
    const [location, setLocation] = useState(currentLocation);
    const [showModal, setShowModal] = useState(false);
    const prevLocation = usePrevious(currentLocation);
    const [addresses, setAddresses] = useState([]);
    const [oneAddress, setOneAddress] = useState({});
    const [addressesEdit, setAddressesEdit] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({});

    const getAddresses = useCallback(() => {
        getClientAddress()
            .then((response) => {
                setAddresses(response.data.addresses);
                return response;
            })
            .then(() => showLocalLoading(false));
    }, [showLocalLoading]);

    useEffect(() => {
        showLocalLoading(true);
        getAddresses();
        if (
            currentLocation &&
            prevLocation &&
            currentLocation.address !== prevLocation.address
        ) {
            setLocation(currentLocation);
        }
    }, [currentLocation, getAddresses, prevLocation, showLocalLoading]);


    useEffect(() => {
        updateOrderDeliveryType(OrderDeliveryType.IS_DELIVERY)
    }, [pathname, updateOrderDeliveryType])

    const onPlaceSelected = async (place) => {
        const address = addressFormatter(place);
        let selectedLocation = {
            address: address.address,
            city: address.city,
            state: address.state,
            lat: address.lat,
            log: address.log,
            zipCode: address.zip,
        };
        if (
            !selectedLocation.zipCode ||
            !selectedLocation.city ||
            !selectedLocation.state
        ) {
            await getAddressFromLatLng(
                selectedLocation.lat,
                selectedLocation.log
            ).then((res) => {
                const newLocation = addressFormatter(res);

                if (Object.keys(newLocation).length) {
                    delete newLocation.address;
                    delete newLocation.lat;
                    delete newLocation.log;
                    selectedLocation = { ...selectedLocation, ...newLocation };
                }
            });
        }
        setLocation(selectedLocation);
        onAddressSelected && onAddressSelected(selectedLocation);
    };

    const getLocation = (location) => setLocation(location)
    const getEditLocation = (location) => setOneAddress({ ...oneAddress, ...location });

    const renderClientAddresses = () => {
        return (
            <div className="address_lists">
                <div className="addresses_item addresses_header">
                    <div>Address name</div>
                    <div>Address</div>
                    <div>City</div>
                    <div>Country</div>
                    <div>State</div>
                    <div>Zip code</div>
                    <img src={trash} alt="trash" style={{ opacity: 0 }} />
                </div>
                {addresses?.map((address, key) => {
                    let myString = address.showAddress;
                    let mySplitResult = myString.split(" ");
                    let lastWord = mySplitResult[mySplitResult.length - 1];
                    return (
                        <div className="addresses_item" key={key}
                            onClick={() => {
                                setOneAddress(address);
                                setAddressesEdit(true)
                                setEmailValue(address.name)
                            }}>
                            <div>{address.name}</div>
                            <div>{address.address}</div>
                            <div>{address.city}</div>
                            <div>USA</div>
                            <div>{address.state}</div>
                            <div>{lastWord}</div>
                            <img
                                src={trash}
                                alt="trash"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.confirm('Sure?') && deleteClientAddress(address.id).then((data) => {
                                        data.success && getAddresses()
                                    });
                                    setAddresses(state => state.filter(item => item !== address))
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    const editLocation = () => {
        return (
            <div
                className="change_and_add_modal"
                style={{ top: window.pageYOffset + 300 }}
            >
                <div className="modal_card_header">
                    <span>Edit address </span>
                    <img src={close} alt="close" onClick={() => {
                        setLocation({})
                        setAddressesEdit(false)
                    }} />
                </div>
                <div className="addresses_section">
                    <div className="address_header">Address name*</div>
                    <div className="input_wrapper">
                        <input
                            type="text"
                            placeholder="Address name"
                            value={oneAddress.name}
                            onChange={(e) => setOneAddress({ ...oneAddress, name: e.target.value })}
                        />
                    </div>
                    <Addresses
                        getLocation={getEditLocation}
                        type="search"
                        placeholder={inputPlaceholder}
                        title={oneAddress.address || ""}
                        types={["address"]}
                        componentRestrictions={{ country: "us" }}
                        required
                        showButton={false}
                        defaultValue={oneAddress.address}
                    />
                    <button
                        className={
                            (oneAddress?.name && oneAddress?.address)
                                ? ""
                                : "disable"
                        }
                        onClick={() => {
                            if (oneAddress.name && oneAddress?.address) {
                                showLocalLoading(true);
                                editClientAddress(oneAddress.id, oneAddress).then((data) => {
                                    getAddresses();
                                    showLocalLoading(false);
                                    setAddressesEdit(false);
                                })
                            }
                        }}
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    }

    const changeAndAddLocation = () => {
        return (
            <div
                className="change_and_add_modal"
                style={{ top: window.pageYOffset + 300 }}
            >
                <div className="modal_card_header">
                    <span>Add new address </span>
                    <img src={close} alt="close" onClick={() => {
                        setLocation({})
                        setShowModal(false)
                    }} />
                </div>
                <div className="addresses_section">
                    <div className="address_header">Address name*</div>
                    <div className="input_wrapper">
                        <input
                            type="text"
                            placeholder="Address name"
                            defaultValue={emailValue}
                            onChange={(e) => setEmailValue(e.target.value)}
                        />
                    </div>
                    <Addresses
                        getLocation={getLocation}
                        type="search"
                        placeholder={inputPlaceholder}
                        title={location.address || ""}
                        onPlaceSelected={onPlaceSelected}
                        types={["address"]}
                        componentRestrictions={{ country: "us" }}
                        required
                        showButton={false}
                    />
                    <button
                        className={
                            (emailValue.length || location.address)
                                ? ""
                                : "disable"
                        }
                        onClick={() => {
                            if (emailValue.length && location.address) {
                                showLocalLoading(true);
                                if (Object.keys(selectedLocation).length > 0) {
                                    changeClientAddress(selectedLocation.id, {
                                        ...selectedLocation,
                                        ...location,
                                        name: emailValue,
                                    }).then(() => {
                                        getAddresses();
                                        setSelectedLocation({});
                                        setLocation({})
                                        setShowModal(false);
                                    });
                                } else {
                                    addClientAddress({
                                        ...location,
                                        name: emailValue,
                                        zipCode: location.zip,
                                    }).then(() => {
                                        getAddresses();
                                        setSelectedLocation({});
                                        setLocation({})
                                        setShowModal(false);
                                        setEmailValue("");
                                    });
                                }
                            }
                        }}
                    >
                        Add
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            {!localLoading && (
                <div className="addresses_wrapper">
                    <button
                        className="add_new_address"
                        onClick={() => {
                            setEmailValue('')
                            setShowModal(true)
                        }}
                    >
                        Add New
                    </button>
                    <ModalWrapper
                        height={window.pageYOffset - 170}
                        showModal={showModal}
                        closeModal={() => {
                            setLocation({})
                            setShowModal(false)
                        }}
                        setData={() => setSelectedLocation({})}
                    >
                        {changeAndAddLocation()}
                    </ModalWrapper>
                    <ModalWrapper
                        height={window.pageYOffset - 170}
                        showModal={addressesEdit}
                        closeModal={() => setAddressesEdit(false)}
                        setData={() => setSelectedLocation({})}
                    >
                        {editLocation()}
                    </ModalWrapper>
                    {renderClientAddresses()}
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    localLoading: state.localLoading,
});

const mapDispatchToProps = {
    updateLocation,
    updateOrderDeliveryType,
    showLocalLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressesSettings);
