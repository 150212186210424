import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { getFavoriteItem } from '../../../api/Home.API';
import ProductItem from '../../RegularMenuComponent/Product/ProductItem';
import RestaurantItem from '../../RegularMenuComponent/Restaurant/RestaurantItem';
import PopUP from '../../RegularMenuComponent/PopUP';
import ModalWrapper from "./../../ModalWrapper/ModalWrapper";
import "../../../styles/Dashboard/Dashboard.scss";
import undraw from "../../../img/undraw.png";
import { showModalRegularMenu, showLocalLoading } from '../../../actions/baseActions';

const MyFavorites = ({ showModal, localLoading, showModalRegularMenu, showLocalLoading }) => {
    const [favoritesProduct, setFavoritesProduct] = useState([]);
    const [favoritesRestaurants, setFavoritesRestaurants] = useState([]);
    const [ifFavoriteChange, setIfFavoriteChange] = useState({});

    useEffect(() => {
        showLocalLoading(true);
        getFavoriteItem()
            .then(result => {
                const { data } = result;
                setFavoritesProduct(data?.products?.available);
                setFavoritesRestaurants(data?.restaurants?.available);
            }).finally(() => {
                showLocalLoading(false);
            });
    }, [ifFavoriteChange]);

    return (
        <>
            <div className='favorites_wrapper'>
                {!localLoading && !favoritesProduct.length && !favoritesRestaurants.length
                    ? (
                        <div className='no_favorete_products'>
                            <img src={undraw} alt="undraw" />
                            <div>No favorites yet</div>
                        </div>
                    ) : (
                        <>
                            {!!favoritesProduct.length && (
                                <div className='favorites_products'>
                                    <div className='favorites_header' style={{ width: "100%" }}>Food</div>
                                    {favoritesProduct.map((products, key) => (
                                        <ProductItem
                                            key={key}
                                            item={products.product}
                                            removeFavoriteProduct={() => setIfFavoriteChange({})}
                                        />
                                    ))}
                                </div>
                            )}
                            {!!favoritesRestaurants.length && (
                                <div className='favorites_products'>
                                    <div className="favorites_header" style={{ width: "100%" }}>Restaurant</div>
                                    {favoritesRestaurants.map((product, key) => (
                                        <RestaurantItem
                                            key={key}
                                            item={product.restaurant}
                                            removeFavoriteProduct={() => setIfFavoriteChange({})}
                                        />
                                    ))}
                                </div>
                            )}
                        </>
                    )}
            </div>
            <ModalWrapper
                height={window.pageYOffset}
                showModal={showModal}
                closeModal={showModalRegularMenu}
            >
                <PopUP height={window.pageYOffset + 400} />
            </ModalWrapper>
        </>
    );
}

const mapStateToProps = state => ({
    isAuth: state.user.isAuth,
    user: state.user,
    driverMapVisible: state.modalVisible.driverMap,
    driverFeedbackVisible: state.modalVisible.driverFeedback,
    driverSendAddressNoteVisible: state.modalVisible.driverAddressNote,
    driverOrderAddressNotesVisible: state.modalVisible.addressNotes,
    showModal: state.regularMenu.showModal,
    selectedProduct: state.regularMenu.selectedProduct,
    localLoading: state.localLoading
});

const mapDispatchToProps = {
    showModalRegularMenu,
    showLocalLoading
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyFavorites);
