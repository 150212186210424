import React from 'react'
import s from './style.module.scss'
import { useHistory } from "react-router-dom";
import { addFirstItem } from '../../../actions/basketActions';
import { useDispatch } from 'react-redux';
import { clearBasket } from '../../../api/Basket.API';

function AnotherRestaurant({ setAnotherRestaurant }) {
    const history = useHistory()
    const dispatch = useDispatch()

    const clearCart = () => {
        setAnotherRestaurant(false)
        clearBasket().then(() => {
            dispatch(addFirstItem())
        })
    }

    return (
        <div className={s.anotherRestaurant}>
            <p>
                You can not add this item to cart,
                because it is from another restaurant. If you want to order
                this item, you must clear your cart or complete the first order.
            </p>
            <div className={s.buttons}>
                <button onClick={clearCart}>Clear cart</button>
                <button onClick={() => history.push("/shopping-cart")}>Checkout</button>
            </div>
        </div>
    )
}

export default AnotherRestaurant