import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import numeral from 'numeral';
import { Button } from 'antd';
import {
    removeBasketItem,
    calculateBasket,
    changeItemCount,
    clearBasketAction, addFirstItem, removeLocalBasketItem
} from "../../actions/basketActions";
import Modal from '../HelpersComponents/Modal.Component';
import { showModal } from '../../actions/baseActions'
import ModalTypes from "../../enums/Modal.Types.Enum";
import Paths from "../../services/Paths";
import { messageType, showMessage } from "../../services/Utilities";
import blackClose from "../../img/blackClose.png"
import { removeBasket } from '../../api/Basket.API';
import { changeStep } from '../../actions/checkoutAction';

function BasketModal(props) {
    const { basket, showModal, basketModalVisible } = props;
    const { productsCount, items, subTotal } = basket;
    const [filterItems, setFilterItems] = useState([]);

    const closeModal = () => {
        showModal(ModalTypes.BASKET_MODAL, false)
    }

    useEffect(() => {
        props.addFirstItem()
    }, []);

    useEffect(() => {
        if (items) setFilterItems([...items])
    }, [items]);

    const handleCheckOut = () => {
        props.changeStep(2)
        props.showModal(ModalTypes.BASKET_MODAL, false)
        props.history.push("/shopping-cart");
    };

    // const handleClearCart = () => {
    //     props.showModal(ModalTypes.BASKET_MODAL, false);
    //     props.clearBasketAction();
    // };

    const handleAddItem = () => {
        props.showModal(ModalTypes.BASKET_MODAL, false)
        const basket = props.basket;
        props.changeStep(1)
        props.history.push({
            pathname: Paths.shoppingCart,
            state: { isCatering: basket.isCatering }
        })
    };

    // const getOrderTotal = () => {
    //     const { basket, isDelivery } = props;
    //     return numeral(addTax(basket.subtotal) + (isDelivery ? basket.delivery : 0)).format('$ 0,0[.]00');
    // };

    // const openItemDetailsModal = (props) => {
    //     props.showModal(ModalTypes.ITEM_DETAILS_MODAL, true, props)
    // }

    useEffect(() => {
        window.addEventListener('click', closeModal)
        return () => window.removeEventListener('click', closeModal)
    }, [])

    return (
        <Modal
            cancelButtonProps={{ style: { display: 'none' } }}
            className={'CustomBasketModal'}
            prefixCls={'Modal'}
            visible={basketModalVisible}
            onCancel={closeModal}
        >
            {productsCount ? (
                <>
                    <ul onClick={e => e.stopPropagation()}>
                        <div className='modal_name'>Cart</div>
                        {filterItems.map(item => (
                            <li key={item.id} className="basket_wrapper">
                                <img className='product_img' src={item?.product.downloadLink}
                                    alt={item.product.name} />
                                <div>
                                    <p>{item.product.name}</p>
                                    <span>{item.count} X {numeral(item.price).format('$ 0,0[.]00')}</span>
                                </div>
                                <img className='close_button' src={blackClose} alt="blackClose" onClick={() => {
                                    setFilterItems(filterItems.filter(f => f.id !== item.id))
                                    removeBasket(item.id)
                                        .then(() => props.addFirstItem())
                                        .catch((error) => showMessage(messageType.error, error))
                                }} />
                            </li>
                        ))
                        }
                    </ul>
                    <p className={'YouPay'}>Subtotal<span>${(Math.round(subTotal * 100) / 100).toFixed(2)}</span>
                    </p>
                    <div className="CheckOut">
                        <Button className={'AddItemButton'} onClick={handleAddItem}>View cart</Button>
                        <button className={'CheckOutButton'} onClick={handleCheckOut}>Check Out</button>
                    </div>
                </>
            ) : (
                <div className={'CheckOut Empty'}>
                    <p className={'text-center'}>Basket Empty</p>
                </div>
            )}
        </Modal>
    )
}

BasketModal.propTypes = {
    basket: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    removeBasketItem: PropTypes.func.isRequired,
    calculateBasket: PropTypes.func.isRequired,
    basketModalVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
    basket: state.basket,
    basketModalVisible: state.modalVisible.basket,
    isDelivery: state.isDelivery,
    isAuth: state.user.isAuth,
    isCatering: state.isCatering
});

const mapDispatchToProps = {
    showModal,
    removeBasketItem,
    calculateBasket,
    changeItemCount,
    clearBasketAction,
    addFirstItem,
    changeStep,
    removeLocalBasketItem
};

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(BasketModal));
