import * as Http from "./Http.Client";

export const getBasket = () => {
	return Http.Get('api/v1/basket/get/items', {}, true)
		.then(result => result);
};

export const updateBasket = (itemId, data) => {
	return Http.Post(`api/v1/basket/update/${itemId}`, {}, data, true)
		.then(result => result);
};
export const removeBasket = (itemId) => {
	return Http.Delete(`api/v1/basket/item/${itemId}`, {}, {}, true, true)
		.then(result => result);
};
export const saveBasket = () => {
	return Http.Post(`api/v1/basket/save`, {}, {}, true, true)
		.then(result => result);
};

export const addProductToBusket = (productSlug, data = {}) => {
return	Http.Put(`api/v1/basket/add/${productSlug}`, {}, data, true, true)
		.then(Http.HandleResponseState);
};
export const clearBasket = () => {
	return Http.Delete(`api/v1/basket/clear`, {}, {}, true, true)
		.then(result => result);
};
