import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NotificationsModal from "./Notifications.Modal.Component";
import SignInModal from "./SignIn.Modal.Component";
import BasketModal from "./Basket.Modal.Component";
import RegistrationModal from "./Registration.Modal.Component";
import ForgotPasswordModal from "./Forgot.Password.Modal.Component";
import ChangePasswordModalComponent from './Change.Password.Modal.Component';
import DeliveryOption from '../ShoppingCart/DeliveryOption';

export const Modals = ({
	notificationModalVisible,
	signInModalVisible,
	registrationModalVisible,
	forgotPasswordModalVisible,
	basketModalVisible,
	changePasswordModalVisible,
	locationModalVisible
}) => {
	const [isOpen, setIsopen] = useState(false);

	useEffect(() => {
		if(locationModalVisible) {
			setIsopen(true)
		} else {
			setIsopen(false)
		}
	}, [locationModalVisible])

	return (
		<>
			{notificationModalVisible && <NotificationsModal />}
			{basketModalVisible && <BasketModal />}
			{changePasswordModalVisible && <ChangePasswordModalComponent />}
			{signInModalVisible && (<SignInModal />)}
			{registrationModalVisible && (<RegistrationModal />)}
			{forgotPasswordModalVisible && (<ForgotPasswordModal />)}
			{locationModalVisible && (<DeliveryOption isOpen={isOpen} setIsopen={setIsopen}/>)}
		</>
	)
};

Modals.propTypes = {
	notificationModalVisible: PropTypes.bool.isRequired,
	basketModalVisible: PropTypes.bool.isRequired,
	signInModalVisible: PropTypes.bool.isRequired,
	registrationModalVisible: PropTypes.bool.isRequired,
	forgotPasswordModalVisible: PropTypes.bool.isRequired,
	changePasswordModalVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
	notificationModalVisible: state.modalVisible.notification,
	basketModalVisible: state.modalVisible.basket,
	signInModalVisible: state.modalVisible.signIn,
	registrationModalVisible: state.modalVisible.registration,
	forgotPasswordModalVisible: state.modalVisible.forgotPassword,
	changePasswordModalVisible: state.modalVisible.changePassword,
	locationModalVisible: state.modalVisible.locationModal,
	width: state.width
});

export default connect(
	mapStateToProps,
	{},
)(Modals);
