import React, { useEffect } from 'react';
import { Icon } from "./imgConfig";
import "../../../styles/Dashboard/Dashboard.scss";
import { connect } from "react-redux";
import { changeSelectedSettings } from "../../../actions/baseActions";

const Dashboard = ({ changeSelectedSettings, selectedSettings }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    }, []);

    let dashboardItem = [
        {
            id: 1,
            name: "Settings",
            icon: Icon.Setting,
            redIcon: Icon.redSetting,
            selected: false,
            route: "/settings",
            option: "settings"
        },
        {
            id: 2,
            name: "Addresses",
            icon: Icon.mapPin,
            redIcon: Icon.redmapPin,
            selected: false,
            route: "/addresses",
            option: "addresses"
        },
        {
            id: 3,
            name: "Payment Methods",
            icon: Icon.creditCard,
            redIcon: Icon.redcreditCard,
            selected: false,
            route: "/payment-methods",
            option: "paymentMethods"
        },
        {
            id: 4,
            name: "My preferences",
            icon: Icon.TricketStar,
            redIcon: Icon.redTricketStar,
            selected: false,
            route: "/my-preferences",
            option: "myPreferences"
        },
        {
            id: 5,
            name: "My Favorites",
            icon: Icon.Heart,
            redIcon: Icon.redHeart,
            selected: false,
            route: "/my-favorites",
            option: "myFavorites"
        },
        // {
        //     id: 6,
        //     name: "My coupons",
        //     icon: Icon.Coupon,
        //     redIcon: Icon.redCoupon,
        //     selected: false,
        //     route: "/my-coupons",
        //     option: "myCoupons"
        // },
        {
            id: 7,
            name: "Order history",
            icon: Icon.Order,
            redIcon: Icon.redOrder,
            selected: false,
            route: "/order-history",
            option: "orderHistory"
        },
        {
            id: 8,
            name: "FAQ",
            icon: Icon.FAQ,
            redIcon: Icon.redFAQ,
            selected: false,
            route: "/faq",
            option: "faq"
        },
        {
            id: 9,
            name: "Privacy Policy",
            icon: Icon.Privacy,
            redIcon: Icon.redPrivacy,
            selected: false,
            route: "/privacy-policy",
            option: "privacyPolicy"
        },
        {
            id: 10,
            name: "Terms & Conditions",
            icon: Icon.Terms,
            redIcon: Icon.redTerms,
            selected: false,
            route: "/terms-conditions",
            option: "termsConditions"
        },
        {
            id: 11,
            name: "COVID-19 information center",
            icon: Icon.SheildDone,
            redIcon: Icon.SheildDone,
            selected: false,
            route: "/covid19",
            option: "covid19"
        },
    ];
    return <div className="dashboard_wrapper">
        <div className='dashboard_header'>DASHBOARD</div>
        <ul>
            {dashboardItem.map((item) => {
                let icon = item.icon;
                if (item.option === selectedSettings) icon = item.redIcon

                return <li
                    className={item.option === selectedSettings ? "active" : ""}
                    onClick={() => changeSelectedSettings(item.option)}
                    key={item.id}>
                    <img src={icon} alt={item.icon} />
                    {item.name}
                </li>
            })}
        </ul>
    </div>
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    selectedSettings: state.selectedSettings
});
const mapDispatchToProps = {
    changeSelectedSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

