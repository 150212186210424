import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Modal from "../HelpersComponents/Modal.Component";
import { showModal } from "../../actions/baseActions";
import { userChangePassword } from "../../actions/userActions";

import ModalTypes from "../../enums/Modal.Types.Enum";
import { Button, Input, Form, Spin } from "antd";

const regexp = /((?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$)/;


const ChangePasswordModal = (props) => {

  const { loading, changePasswordModalVisible, showModal, userChangePassword } = props;

  const handleSubmit = values => {
     userChangePassword({
      code: values.code,
      first: values.newPassword,
      second: values.repeatPassword,
      email: localStorage.getItem("Email")
    }).then(res => {
       
    });
  };

  return (
    <div>
      <Modal
        className={"CustomChangePassword"}
        visible={changePasswordModalVisible}
        onCancel={() => showModal(ModalTypes.CHANGE_PASSWORD_MODAL, false)}
      >
        <Spin spinning={loading}>
          <h2 className={"ChangePasswordTitle text-center"}>Change Password</h2>

          <Form onFinish={handleSubmit} className={"ChangePasswordForm"}>
            <Form.Item
              name="code"
              label="Current PasswordReset Password Code"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input Email Reset password code!"
                }
              ]}
            >
              <Input type='password' />
            </Form.Item>

            <Form.Item
              label={"New Password"}
              name={"newPassword"}
              rules={[
                {
                  required: true,
                  message: "Please input your New Password!"
                },
                () => ({
                  validator(rule, value) {
                    if (!value || value.match(regexp)) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Password must contains at least 6 characters, including UPPER/lower case and numbers.");
                  }
                })
              ]}
              hasFeedback
            >
              <Input type='password' />
            </Form.Item>

            <Form.Item
              label={"Repeat New Password"}
              hasFeedback
              name={"repeatPassword"}
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: 'Please input Repeat Password!'
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Two passwords that you enter is inconsistent!");
                  }
                })
              ]}
            >
              <Input type='password' />
            </Form.Item>
            <Button  type="primary" htmlType="submit" className="change-password-form-button">
              Change
            </Button>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

ChangePasswordModal.propTypes = {
  changePasswordModalVisible: PropTypes.bool.isRequired,
  userChangePassword: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    changePasswordModalVisible: state.modalVisible.changePassword,
    loading: state.loading
  };
};

const mapDispatchToProps = { showModal, userChangePassword };


export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
