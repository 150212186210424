import React, { memo } from 'react';
import { connect } from 'react-redux';
import { addFavoritesProduct, removeFavoritesProduct } from '../../../api/Product';
import ReviewsMaker from './../../../container/ReviewsMaker/ReviewsMaker';

import { img } from './img.js';
import { changeRestaurantIsFavorites } from "./../../../actions/baseActions";

const RestaurantInfo = memo(({ restaurant, width, changeRestaurantIsFavorites, isAuth }) => {
    const {
        rating,
        phone,
        reviewsCount,
        openNow,
        workingHours,
        showProductPriceAVG,
        showRestaurantTypes = "",
        address: { showAddress },
        isFavorite,
        showClientDistance
    } = restaurant
    return (
        <div className='selected_restaurant_about'>
            <div className='selected_restaurant_card'>
                <div className='selected_restaurant_picture'>
                    {!openNow && <div className='restaurant_is_closed'>
                        <img src={img.closed} alt={"closed"} />
                    </div>}
                    <div
                        style={{ backgroundImage: `url(${restaurant?.downloadLink})` }}
                        className="food_list_img"></div>
                </div>
                <div className='selected_restaurant_description'>
                    <div className='sr_rateing'>
                        <ReviewsMaker rating={rating} count={reviewsCount} type={true} />
                        {width < 635 && <><span>|{showRestaurantTypes}|{showProductPriceAVG}|</span></>}
                        {!isAuth ? null : !isFavorite
                            ?
                            <img src={img.heart} alt="heart" onClick={() => {
                                addFavoritesProduct(restaurant.slug, "restaurant").then(changeRestaurantIsFavorites)
                            }} />
                            :
                            <img src={img.redHeart} alt="redHeart" onClick={() => {
                                removeFavoritesProduct(restaurant.slug).then(changeRestaurantIsFavorites)
                            }} />
                        }
                    </div>
                    <div className='address'>
                        {showAddress}/{showClientDistance}
                    </div>

                    {width > 635 && <div className='order_price'>
                        <span>{showRestaurantTypes}</span>
                    </div>}
                    <div className='order_price'>
                        <span>Open {workingHours[new Date().getDay()]?.times[0].openTime}AM - {workingHours[new Date().getDay()]?.times[0].closeTime}PM</span>
                    </div>
                </div>
            </div>
            <div className='selected_restaurant_contact'>
                <div>Need help?</div>
                <button onClick={() => {
                }}>Contact with Support
                </button>
                <h2>OR</h2>
                <h1>{phone}</h1>
            </div>
        </div>
    )
})

const mapStateToProps = state => ({
    isAuth: state.user.isAuth,
});

const mapDispatchToProps = {
    changeRestaurantIsFavorites
};
export default connect(mapStateToProps, mapDispatchToProps)(RestaurantInfo);