import React, { useState, useEffect } from 'react';
import "../../styles/ShoppingCart/ShoppingCart.scss";
import recycleBin from "../../img/recycleBin.png";
import { removeBasket, updateBasket } from "../../api/Basket.API";
import { connect } from "react-redux";
import { addFirstItem } from '../../actions/basketActions';

const ShoppingCartItem = ({
    state,
    setState,
    addFirstItem,
    item: {
        count, price, id,
        product: { downloadLink, description, name },
    }
}) => {
    const [counts, setCounts] = useState(count);
    const [debouncedChangeCount, setDebouncedChangeCount] = useState(null);

    const changeCount = () => {
        updateBasket(id, { count: counts }).then(addFirstItem);
    }

    const debounce = (func, delay) => {
        return function (...args) {
            if (debouncedChangeCount) {
                clearTimeout(debouncedChangeCount);
            }

            const context = this;

            const later = function () {
                func.apply(context, args);
            };

            setDebouncedChangeCount(setTimeout(later, delay));
        };
    };

    const debouncedChangeCountHandler = debounce(changeCount, 700);

    useEffect(() => {
        setCounts(count)
    }, [state])

    useEffect(() => {
        return () => {
            if (debouncedChangeCount) {
                clearTimeout(debouncedChangeCount);
            }
        };
    }, [debouncedChangeCount]);

    return (
        <div className='sc_item_wrapper' key={id}>
            <div className='sc_item_image' style={{ backgroundImage: `url(${downloadLink})` }}></div>
            <div className='sc_item_info'>
                <h1>{name}</h1>
                <h2>{description}</h2>
            </div>
            <div className='sc_item_count'>
                <div onClick={() => {
                    if (counts > 1) {
                        setCounts(counts - 1);
                        debouncedChangeCountHandler()
                    }
                }}>
                    -
                </div>
                <span>{counts}</span>
                <div onClick={() => {
                    setCounts(counts + 1);
                    debouncedChangeCountHandler()
                }}>
                    +
                </div>
            </div>
            <div className='sc_item_price'>
                <span>${price}</span>
                <button onClick={() => {
                    setState(state.filter(item2 => item2.id !== id));
                    removeBasket(id).finally(() => addFirstItem());
                }}>
                    <img src={recycleBin} alt="recycleBin" /> Remove
                </button>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    addFirstItem
};

export default connect(null, mapDispatchToProps)(ShoppingCartItem);
