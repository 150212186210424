import React from "react";
import Config from "Config";
import Autocomplete from "react-google-autocomplete";
import { GoogleApiWrapper } from "google-maps-react";

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementWidth: null,
    };
    this.address = React.createRef();
    this.wrapperDivRef = React.createRef();
  }

  componentDidMount() {
    const elementWidth = this.wrapperDivRef.current.offsetWidth;
    this.setState({ elementWidth });
    if (this.address.current) {
      if (this.props.isDelivery) {
        this.address.current.refs.input.disabled = false;
      } else if (!this.props.isDelivery) {
        this.address.current.refs.input.disabled = true;
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.address.current?.refs) {
      if (this.address.current && "input" in this.address.current.refs) {
        this.address.current.refs.input.value = nextProps.defaultValue
          ? nextProps.defaultValue
          : "";
      }
      if (nextProps.isDelivery) {
        this.address.current.refs.input.disabled = false;
      } else if (!nextProps.isDelivery) {
        this.address.current.refs.input.disabled = true;
        this.address.current.refs.input.value = "";
      }
    }
  }

  handleOnChange = (evt) => {
    if (this.props.getInputState) {
      if (this.props.getInputState !== undefined) {
        this.props.getInputState(evt.target.value);
      } else if (this?.address?.current?.refs?.input?.value === "") {
        this.props.getInputState("");
      }
    }
  };

  render() {
    return (
      <div
        ref={this.wrapperDivRef}
        className="vehicleInformation"
        style={!this.props.isDelivery ? { opacity: "0.7" } : { opacity: 1 }}
      >
        <Autocomplete
          {...this.props}
          disabled={!this.props.isDelivery}
          onChange={this.handleOnChange}
          loaded={this.props.loaded ? "true" : "false"}
          ref={this.address}
          types={[]}
        />
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: Config.GoogleMapKey,
})(Address);
